* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, html {
  width: 100%;
  height: 100%;
  background: #e6e6e6;
}

#root {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}